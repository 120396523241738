import React, { useState, useRef } from "react"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactModal from 'react-modal'

const customStyles = {
  overlay: {
    backgroundColor: "#FFF6E1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto",
    overflowX: "none"
  },
  content: {
    inset: "initial",
    position: "relative",
    margin: "auto",
    border: "initial",
    backgroundColor: "transparent",
    overflow: "initial"
  }
}

if (typeof(window) !== 'undefined') ReactModal.setAppElement('body');

export default function HechosInicio({tituloPlanos, textoPlanos, planos, nro, etiqueta}) {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const sliderPlanos = useRef();
  // const sliderGaleriaPlano = useRef();

  const handleModalOpen = e => {
    setIsModalOpen(true);
    console.log("data set",e.target.dataset.id );
    setTimeout(() => {
      sliderPlanos.current.slickGoTo(e.target.dataset.id - 1)
    })
    // setTimeout(()=>{
    //   sliderPlanos.current.slickGoTo(e.target.dataset.id - 1);
    // });
  }

  const handleModalClose = event => {
    setIsModalOpen(false)
  }

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "arrow-orange-100 slick-prev slick-arrow " +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <svg width="31" height="56" viewBox="0 0 31 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29.8168 54.9066L3 28.4533L29.8168 2" stroke="#FF644D" strokeWidth="3" strokeMiterlimit="10"/></svg>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "arrow-orange-100 slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <svg width="31" height="56" viewBox="0 0 31 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.99959 2.0001L28.8164 28.4534L1.99959 54.9067" stroke="#FF644D" strokeWidth="3" strokeMiterlimit="10"/></svg>
    </button>
  );

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    pauseOnFocus: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  }

  const settings2 = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 7000,
    pauseOnHover: false,
    fade: true,
    pauseOnFocus: false,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true
        }
      }
    ]
  }

  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()

  const handlePlanos = event => {
    nav2.slickGoTo(event.target.value);
    setIsModalOpen(true);
    setTimeout(()=>{
      sliderPlanos.current.slickGoTo(event.target.value);
    });
  }

  return (
    <section className="bg-yellow-100 px-81 pt-123 planos mov-px-50 mov-pt-45" id="planos">
      <div className="flex pb-125 mov-block mov-pb-35">
        <div className="w-128 shrink-0 relative mov-w-0">
          <div className="vertical mt-20 mov-mt-10">
            <span className="color-orange-100 font-poppins size-16 mov-size-12">{nro}.</span>
            <span className="vertical__line inline-block bg-black-100"/>
            <span className="color-orange-100 font-poppins size-16 mov-size-12">{etiqueta}</span>
          </div>
        </div>
        <div className="grid-100 px-0">
          <div className="grid-30 px-0">
            <h2 className="color-orange-100 size-52 font-poppins weight-400 text-mt-0 mov-size-30"
                dangerouslySetInnerHTML={{ __html: tituloPlanos }} />
            <div className="size-22 font-tenor mov-size-16-3 mov-br-none color-blue-100" dangerouslySetInnerHTML={{ __html: textoPlanos }} />
          </div>
          <div className="grid-70 px-0">

            <select className="select--planos mov-mb-38 mov-mt-15 only-mov font-poppins mov-size-16 color-blue-100" name="planos" onChange={ e => handlePlanos(e)}>
              {planos &&
                planos.map((plano, i) => (
                  <option key={i} value={i}>{plano.metraje} m2</option>
                ))
              }
            </select>

            <Slider {...settings} className="mb-43 only-desk" asNavFor={nav2} ref={(slider1) => setNav1(slider1)}
                    lazyLoad={true} draggable={true} touchMove={true} swipeToSlide={true} focusOnSelect={true}>
              {planos &&
              planos.map((plano, i) => (
                <div>
                  <h3 key={i}
                      className="plano-tab text-center mb-43 font-poppins weight-300 size-20 color-green-100 pointer">
                    <span className="plano-tab-span1">{plano.metraje} M²</span>
                    <span className="plano-tab-span2">{plano.dorms}</span>
                  </h3>
                </div>
              ))
              }
            </Slider>
            <Slider
              className="slick-arrow-none mxw-920"
              asNavFor={nav1}
              ref={(slider2) => setNav2(slider2)}
              slidesToShow={1}
              swipeToSlide={false}
              focusOnSelect={true}
              infinite={false}
              responsive={[
              {
                breakpoint: 767,
                settings: {
                fade: true
                }
              }
                ]
              }
            >
              {planos &&
              planos.map((plano, index) => (
                <div className="px-14" key={index}>
                {
                  plano.vista &&
                  <GatsbyImage className="" image={plano.vista.sourceUrlSharp.childImageSharp.gatsbyImageData} alt="holamundo" />
                }
                <h3 className="color-yellow-100 text-center font-poppins uppercase weight-400 mt-14 spacing-029em"
                dangerouslySetInnerHTML={{ __html: plano.metraje }} />
                  <div className="flex">
                    <div className="mr-32 mov-mr-14" data-id={plano.index} onClick={(event)=> handleModalOpen(event)} style={{cursor:"pointer"}}>
                      <svg className="h-53 mov-h-32" style={{pointerEvents:"none"}} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="26.5" cy="26.5" r="26.5" fill="#1B2B3A"/>
                        <path d="M20.9251 28.4142C25.8856 28.4142 29.9069 24.393 29.9069 19.4325C29.9069 14.472 25.8856 10.4507 20.9251 10.4507C15.9646 10.4507 11.9434 14.472 11.9434 19.4325C11.9434 24.393 15.9646 28.4142 20.9251 28.4142Z" stroke="#FFF6E1" strokeWidth="3" strokeMiterlimit="10"/>
                        <path d="M15.709 19.2329H26.3733" stroke="#FFF6E1" strokeWidth="3" strokeMiterlimit="10"/>
                        <path d="M21.0391 24.5666V13.9023" stroke="#FFF6E1" strokeWidth="3" strokeMiterlimit="10"/>
                        <path d="M30.0592 26.0937L27.4141 28.7388L40.3758 41.7006L43.0209 39.0555L30.0592 26.0937Z" fill="#FFF6E1"/>
                      </svg>
                    </div>
                    <button className="size-22 font-tenor btn-outline-blue-100 w-346 h-53 mov-h-32 font-sandbleu mov-size-16 mov-w-228 color-blue-100" data-id={plano.index} onClick={(event)=> handleModalOpen(event)}>Ver detalle</button>
                    {/*<button className="size-22 font-tenor btn-outline-blue-100 w-346 h-53 mov-h-32 font-sandbleu mov-size-16 mov-w-228 only-mov">Descargar Planos</button>*/}
                  </div>
                </div>
              ))
              }
            </Slider>

          </div>
        </div>
      </div>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Modal Cotizar"
        style={customStyles}
      >
        <div className="modal--planos center-vertical">
          <div className="text-right">
            <button onClick={handleModalClose} className="modal-cerrar">
              <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M42 42L1 1" stroke="#FF5D44" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M42 0.999999L1 42" stroke="#FF5D44" strokeWidth="2" strokeMiterlimit="10"/>
              </svg>
            </button>
          </div>
          <div className="mxw-1697 mx-auto">
            <Slider {...settings2} className="slick-arrow-x-n66 slick-dots--orange-100 mov-slick-dots-b-5" ref={sliderPlanos}>
              {planos &&
              planos.map((plano, i) => (
                <div key={i}>
                  {
                    plano.galeriaTipologias &&
                    <GatsbyImage
                      className="only-desk w-full"
                      image={plano.galeriaTipologias.sourceUrlSharp.childImageSharp.gatsbyImageData}
                      alt={plano.galeriaTipologias.alt ? plano?.galeriaTipologias.alt : plano.galeriaTipologias.title} />
                  }
                  {
                    plano.galeriaTipologiasMovil &&
                    <GatsbyImage
                      className="only-mov w-full contain"
                      image={plano.galeriaTipologiasMovil.sourceUrlSharp.childImageSharp.gatsbyImageData}
                      alt={plano.galeriaTipologiasMovil.alt ? plano.galeriaTipologiasMovil.alt : plano.galeriaTipologiasMovil.title} />
                  }
                </div>
              ))
              }
            </Slider>
          </div>
        </div>
      </ReactModal>
    </section>
  );

}
