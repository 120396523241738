import React from "react"
import {encode as base64_encode} from 'base-64';
// import  fileSaver from 'file-saver'

export default function DisenioInicio({ tituloEdificio, textoEdificio, etiqueta,nro, pdf }) {

  // const saveFile = (url) => {
  //   // fileSaver.saveAs(url, 'Brochure Labok.pdf');
  //   fetch(url)
  //     .then(res => res.blob())
  //     .then((blob) => {
  //       fileSaver.saveAs(blob, 'Brochure Labok.pdf');
  //     })
  // }

  return (
    <section className="bg-blue-100 px-81 pt-84 flex pb-69 el-proyecto mov-px-50 mov-pt-34 mov-pb-67" id="el-proyecto">
      <div className="w-128 shrink-0 relative mov-w-0">
        <div className="vertical mt-20 mov-mt-10">
          <span className="color-yellow-100 font-poppins size-16 mov-size-12">{nro}.</span>
          <span className="vertical__line inline-block bg-orange-100"></span>
          <span className="color-yellow-100 font-poppins size-16 mov-size-12">{etiqueta}</span>
        </div>
      </div>
      <div className="grid-100 px-0">
        <div className="grid-50 tablet-grid-50 mobile-grid-100 px-0">
          <div className="color-yellow-100 size-72 font-poppins text-my-0 color-yellow-100 mov-size-30 mov-mb-23"
               dangerouslySetInnerHTML={{ __html: tituloEdificio }} />
          {/* <button className="btn-outline-orenge-100 color-orange-100 size-22 font-sangbleu mt-36 inline-block px-38 pt-26 pb-26 mov-size-16 only-desk" onClick={()=> saveFile(pdf)}>Descargar Brochure</button> */}
          <a href={`/pdf/?url=${base64_encode(pdf)}`} target="_blank" className="btn-outline-orenge-100 color-orange-100 size-22 font-sangbleu mt-36 inline-block px-38 pt-26 pb-26 mov-size-16 only-desk" rel="noreferrer">Descargar Brochure</a>
        </div>
        <div className="grid-50 tablet-grid-50 mobile-grid-100 px-0 bd-left-orange-100">
          <div className="color-white-100 size-20 font-tenor uppercase-list list-mb-20 mxw-491 mov-mxw-auto color-yellow-100 list-disc mx-auto text-mb-44 text-mt-0 mov-size-16-2 mov-br-none mov-text-mb-23 mov-list-mb-15"
               dangerouslySetInnerHTML={{ __html: textoEdificio }} />
          <div className="text-center">
            {/* <button className="btn-outline-orenge-100 color-orange-100 size-22 mt-52 inline-block px-38 pt-26 pb-26 mov-size-16 only-mov weight-300 font-sangbleu mov-mt-28 mov-px-37 mov-pt-5 mov-pb-5" onClick={()=> saveFile(pdf)}>Descargar Brochure</button> */}
            <a href={`/pdf/?url=${base64_encode(pdf)}`} target="_blank" className="btn-brochure btn-outline-orenge-100 color-orange-100 size-22 font-sangbleu mt-36 inline-block px-38 pt-26 pb-26 mov-size-16 only-mov" rel="noreferrer">Descargar Brochure</a>
          </div>
        </div>

      </div>
    </section>
  )

}