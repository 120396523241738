import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"

export default function Edificioinicio({ tituloEco, logoEdge, caracteristicas, galeria1, galeria2, nro, etiqueta }) {

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "arrow-orange-100 slick-prev slick-arrow " +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <svg width="31" height="56" viewBox="0 0 31 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29.8168 54.9066L3 28.4533L29.8168 2" stroke="#FF644D" strokeWidth="3" strokeMiterlimit="10"/></svg>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "arrow-orange-100 slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <svg width="31" height="56" viewBox="0 0 31 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.99959 2.0001L28.8164 28.4534L1.99959 54.9067" stroke="#FF644D" strokeWidth="3" strokeMiterlimit="10"/></svg>
    </button>
  );

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    pauseOnFocus: false,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false
        }
      }
    ]
  }

  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()

  const [nav3, setNav3] = useState()
  const [nav4, setNav4] = useState()


  return (
    <section className="bg-blue-100 px-81 pt-73 pb-89 galeria mov-px-0 mov-pt-59 mov-pb-40" id="galeria">
      <div className="flex pb-89 only-desk">
        <div className="w-128 shrink-0 relative mov-w-0">
          <div className="vertical mt-20">
            <span className="color-yellow-100 font-poppins size-16 mov-size-12">{nro}.</span>
            <span className="vertical__line inline-block bg-orange-100"></span>
            <span className="color-yellow-100 font-poppins size-16 mov-size-12">{etiqueta}</span>
          </div>
        </div>
        <div className="grid-100 px-0">
          <div className="grid-50 px-0">
            <div className="mov-size-30 color-yellow-100 size-72 font-poppins text-my-0 color-yellow-100"
                 dangerouslySetInnerHTML={{ __html: tituloEco }} />
            {
              logoEdge &&
              <GatsbyImage className="only-desk mt-35 mxw-222" image={logoEdge.sourceUrlSharp.childImageSharp.gatsbyImageData} alt="holamundo" />
            }
          </div>
          <div className="grid-50 px-0 bd-left-orange-100">
            <div
              className="mov-size-15 color-white-100 size-20 font-tenor uppercase-list list-mb-20 mxw-491 color-yellow-100 list-disc mx-auto text-mb-44 text-mt-0"
              dangerouslySetInnerHTML={{ __html: caracteristicas }} />
          </div>
        </div>
      </div>

      <h3
        className="text-center color-white-100 font-poppins weight-400 spacing-05em color-yellow-100 size-25 flex-center mxw-1493 mx-auto mb-19 mov-px-16 mov-mb-19">
        <span className="line bg-orange-100 block"></span> <span className="inline-block px-81 mov-size-16 mov-px-50">INTERIORES</span> <span
        className="line bg-orange-100 block"></span></h3>
      <div className="mxw-1493 mx-auto mov-mxw-full">
        <Slider
          className="slick-arrow-none slick-dots--yellow-100 mov-slick-dots-b-n78"
          asNavFor={nav1}
          ref={(slider2) => setNav2(slider2)}
          slidesToShow={1}
          swipeToSlide={false}
          focusOnSelect={true}
          infinite={false}
          responsive={
            [
              {
                breakpoint: 767,
                settings: {
                  dots: true,
                }
              }
            ]
          }
        >
          {galeria1 &&
          galeria1.map((galeria, i) => (
            <div key={i}>
              {galeria.imagen &&
                <GatsbyImage className="mov-h-298 w-full img-border-x--blue-100 only-desk" image={galeria.imagen.sourceUrlSharp.childImageSharp.gatsbyImageData}
                           alt={galeria.imagen.alt ? galeria.imagen.alt : galeria.imagen.title} />
              }
              {
                galeria.imagenMovil &&
                <GatsbyImage className="mov-h-298 w-full img-border-x--blue-100 only-mov" image={galeria.imagenMovil.sourceUrlSharp.childImageSharp.gatsbyImageData}
                             alt={galeria.imagenMovil.alt ? galeria.imagenMovil.alt : galeria.imagenMovil.title} />
              }
            </div>
          ))
          }
        </Slider>
        <h3
          className="text-center size-20 color-white-100 font-poppins weight-400 spacing-05em mt-24 mb-24 color-yellow-100 mov-size-12 mov-mb-88 mov-mt-15">IMÁGENES
          REFERENCIALES*</h3>
        <div className="mxw-1320 mx-auto only-desk">
          <Slider {...settings} className="mx-n14 slick-arrow-x-n66" asNavFor={nav2} ref={(slider1) => setNav1(slider1)}
                  lazyLoad={true} draggable={true} touchMove={true} swipeToSlide={true} focusOnSelect={true}>
            {galeria1 &&
            galeria1.map((galeria, i) => (
              <div key={i} className="px-14">
                {
                  galeria.imagen &&
                  <GatsbyImage className="h-110 w-full" image={galeria.imagen.sourceUrlSharp.childImageSharp.gatsbyImageData} alt={galeria.imagen.alt ? galeria.imagen.alt: galeria.imagen.title} />
                }
                <h3 className="color-yellow-100 text-center font-poppins uppercase weight-400 mt-14 spacing-029em size-16"
                    dangerouslySetInnerHTML={{ __html: galeria.textoImagen }} />
              </div>
            ))
            }
          </Slider>
        </div>

        <h3
          className="text-center color-white-100 font-poppins weight-400 spacing-05em color-yellow-100 size-25 flex-center mxw-1493 mx-auto mb-19 mt-91 mov-px-16 mov-mb-19">
          <span className="line bg-orange-100 block"></span> <span
          className="inline-block px-81 shrink-0 mov-size-16 mov-px-50">EL EDIFICIO</span> <span className="line bg-orange-100 block"></span>
        </h3>
        <Slider
          className="slick-arrow-none slick-dots--yellow-100 mov-slick-dots-b-n78"
          asNavFor={nav3}
          ref={(slider4) => setNav4(slider4)}
          slidesToShow={1}
          swipeToSlide={false}
          focusOnSelect={true}
          infinite={false}
          responsive={
            [
              {
                breakpoint: 767,
                settings: {
                  dots: true,
                }
              }
            ]
          }
        >
          {galeria2 &&
          galeria2.map((galeria, i) => (
            <div key={i}>
              {galeria.imagen &&
                <GatsbyImage className="mov-h-298 w-full img-border-x--blue-100 only-desk" image={galeria.imagen.sourceUrlSharp.childImageSharp.gatsbyImageData}
                             alt="hols" />
              }
              {galeria.imagenCopiar &&
              <GatsbyImage className="mov-h-298 w-full img-border-x--blue-100 only-mov" image={galeria.imagenCopiar.sourceUrlSharp.childImageSharp.gatsbyImageData}
                           alt={galeria.imagenCopiar.alt ? galeria.imagenCopiar.alt : galeria.imagenCopiar.title } />
              }

            </div>
          ))
          }
        </Slider>
        <h3
          className="text-center size-20 color-white-100 font-poppins weight-400 spacing-05em mt-24 mb-24 color-yellow-100 mov-size-12 mov-mt-15 mov-mb-88">IMÁGENES
          REFERENCIALES*</h3>
        <div className="mxw-1320 mx-auto only-desk">
          <Slider {...settings} className="mx-n14 slick-arrow-x-n66" asNavFor={nav4} ref={(slider3) => setNav3(slider3)}
                  lazyLoad={true} draggable={true} touchMove={true} swipeToSlide={true} focusOnSelect={true}>
            {galeria2 &&
            galeria2.map((galeria, i) => (
              <div className="px-14" key={i}>
                {
                  galeria.imagen &&
                  <GatsbyImage className="h-110 w-full" image={galeria.imagen.sourceUrlSharp.childImageSharp.gatsbyImageData}
                               alt="holamundo" />
                }
                <h3 className="color-yellow-100 text-center font-poppins uppercase weight-400 mt-14 spacing-029em size-16"
                    dangerouslySetInnerHTML={{ __html: galeria.textoImagen }} />
              </div>
            ))
            }
          </Slider>
        </div>
      </div>
      <div className="flex pb-89 only-mov mov-px-50">
        <div className="w-128 shrink-0 relative mov-w-0">
          <div className="vertical mt-20">
            <span className="color-yellow-100 font-poppins size-16 mov-size-12">{nro}.</span>
            <span className="vertical__line inline-block bg-orange-100"></span>
            <span className="color-yellow-100 font-poppins size-16 mov-size-12">{etiqueta}</span>
          </div>
        </div>
        <div className="grid-100 px-0">
          <div className="grid-50 px-0">
            <div className="mov-size-30 color-yellow-100 size-72 font-poppins text-my-0 color-yellow-100 mov-mb-23"
                 dangerouslySetInnerHTML={{ __html: tituloEco }} />
              {
                logoEdge &&
                <GatsbyImage className="only-desk" image={logoEdge.sourceUrlSharp.childImageSharp.gatsbyImageData} alt="holamundo" />
              }
          </div>
          <div className="grid-50 px-0 bd-left-orange-100">
            <div
              className="mov-size-16-2 color-white-100 size-20 font-tenor uppercase-list list-mb-20 mxw-491 color-yellow-100 list-disc mx-auto text-mb-44 text-mt-0 mov-list-mb-15"
              dangerouslySetInnerHTML={{ __html: caracteristicas }} />
          </div>
        </div>
      </div>
    </section>
  )

}