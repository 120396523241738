import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Layout/Seo"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import BannerInicio from "../../components/Banner/BannerInicio"
import ViveInicio from "../../components/Inicio/ViveInicio"
import DisenioInicio from "../../components/Inicio/DisenioInicio"
import Edificioinicio from "../../components/Inicio/Edificioinicio"
import HechosInicio from "../../components/Inicio/HechosInicio"
import VideoInicio from "../../components/Inicio/VideoInicio"
import MapaInicio from "../../components/Inicio/MapaInicio"
import SomosInicio from "../../components/Inicio/SomosInicio"
import ArquitectosInicio from "../../components/Inicio/ArquitectosInicio"
import ContactInicio from "../../components/Inicio/ContactInicio"

export const query = graphql`
  query($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        template {
          ... on Wordpress_Template_Inicio {
            inicio {
              logo{ ...imagen}
              foto1{ ...imagen}
              foto2{ ...imagen}
              foto3{ ...imagen}
              galeriaBarrio {...imagen}
              logoEdge{...imagen}
              galeriaInteriores {
                textoImagen
                imagen{...imagen}
                imagenMovil{...imagen}
              }
              galeriaElEdificio {
                textoImagen
                imagen{...imagen}
                imagenCopiar{...imagen}
              }
              logoLabok {...imagen}
              slogan
              caracteristicasEcoFriendly
              caracteristicasIntro
              descripcionArquitectos
              formsContacto
              textosBarrio
              textosEdificio
              textosSobreLabok
              textosSobrePlanos
              tituloArquitectos
              tituloBarrio
              tituloEcoFriendly
              tituloEdificio
              tituloPlanos
              video
              imagenMapa{
                ...imagen
              }
              imagenMapaMovil{
                ...imagen
              }
              brochureEdificio {
                mediaItemUrl
              }
              planos {
                vista{
                  altText
                  title
                  srcSet
                  sourceUrl
                  sourceUrlSharp {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: NONE
                      )
                    }
                  }
                }
                index
                metraje
                dorms
                galeriaTipologias{
                  altText
                  title
                  srcSet
                  sourceUrl
                  sourceUrlSharp {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: NONE
                      )
                    }
                  }
                }
                galeriaTipologiasMovil{
                  altText
                  title
                  srcSet
                  sourceUrl
                  sourceUrlSharp {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: NONE
                      )
                    }
                  }
                }
              }
              puntosDeInteres1
              puntosDeInteres2
              etiquetaFila5
              etiquetaFila4
              etiquetaFila3
              etiquetaFila2
              etiquetaFila1
              numeroFila1
              numeroFila2
              numeroFila3
              numeroFila4
              numeroFila5
              introContacto
              indicadores {
                descripcion
                valor
              }
              imagenDeFondoArquitectos{...imagen}
              imagenDeFondoArquitectosMovil{...imagen}
              proyectosEntregadosDestacados {
                nombre
                imagen{...imagen}
              }
            }
          }
        }
      }
    }
  }
  
  fragment imagen on Wordpress_MediaItem{
    altText
    title
    srcSet
    sourceUrl
    sourceUrlSharp {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
  }
`

export default function Inicio({ data }) {
  const page = data?.wordpress?.page
  const template = page?.template?.inicio
  const pageTitle = page?.title

  return (
    <Layout>
      <Seo title={pageTitle} />
      <BannerInicio
        logo={template.logo}
        slogan={template.slogan}
        caracteristicas={template.caracteristicasIntro}
        foto1={template.foto1}
        foto2={template.foto2}
        foto3={template.foto3}
      />

      <ViveInicio
        tituloBarrio={template.tituloBarrio}
        textoBarrio={template.textosBarrio}
        galeriaBarrio={template.galeriaBarrio}
        etiqueta={template.etiquetaFila1}
        nro={template.numeroFila1}
      />

      <DisenioInicio
        tituloEdificio={template.tituloEdificio}
        textoEdificio={template.textosEdificio}
        etiqueta={template.etiquetaFila2}
        nro={template.numeroFila2}
        pdf={template.brochureEdificio.mediaItemUrl}
      />

      <VideoInicio
        videoUrl={template.video}
      />

      <Edificioinicio
        tituloEco={template.tituloEcoFriendly}
        logoEdge={template.logoEdge}
        caracteristicas={template.caracteristicasEcoFriendly}
        galeria1={template.galeriaInteriores}
        galeria2={template.galeriaElEdificio}
        etiqueta={template.etiquetaFila3}
        nro={template.numeroFila3}
      />

      <HechosInicio
        tituloPlanos={template.tituloPlanos}
        planos={template.planos}
        textoPlanos={template.textosSobrePlanos}
        etiqueta={template.etiquetaFila4}
        nro={template.numeroFila4}
      />

      <MapaInicio
        imagen={template.imagenMapa}
        imagenMovil={template.imagenMapaMovil}
        puntos1={template.puntosDeInteres1}
        puntos2={template.puntosDeInteres2}
      />

      <SomosInicio
        logo={template.logoLabok}
        indicadores={template.indicadores}
        textos={template.textosSobreLabok}
        proyectosEntregados={template.proyectosEntregadosDestacados}
      />

      <ArquitectosInicio
        imagen={template.imagenDeFondoArquitectos}
        imagenMov={template.imagenDeFondoArquitectosMovil}
        titulo={template.tituloArquitectos}
        texto={template.descripcionArquitectos}
        etiqueta={template.etiquetaFila5}
        nro={template.numeroFila5}
      />

      <ContactInicio intro={template.introContacto}/>

    </Layout>
  )
}