import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export default function SomosInicio ({logo, textos, indicadores, proyectosEntregados}) {
  return (
    <section className="sobre-labok bg-orange-100 text-center pt-105 mov-pt-52 pb-105 mov-pb-43 mov-px-50" id="sobre-labok">
      {
        logo &&
        <GatsbyImage className="w-327 mov-w-188" image={logo.sourceUrlSharp.childImageSharp.gatsbyImageData} alt="holamundo" />
      }
      <div className="color-black-100 size-36 font-tenor my-87 mov-mt-52 text-my-0 mov-mb-30 mov-size-16 mov-br-none mov-pb-38 mov-bb-3 color-blue-100" dangerouslySetInnerHTML={{ __html: textos }} />
      <div className="grid-100 mb-133 mov-mb-41 bd-y-3 mxw-1760 mx-auto pt-49 pb-49 mov-bb-3">
        {indicadores &&
        indicadores.map((indicador, i) => (
          <div className="grid-33 mov-mb-42" key={i}>
            <h3 className="size-65 font-euclid mov-poppins color-black-100 mov-size-32">{indicador.valor}</h3>
            <h4 className="size-22 color-black-100 font-tenor mt-11 mov-size-20 mov-weight-400">{indicador.descripcion}</h4>
          </div>
        ))
        }
      </div>
      <div className="grid-100 px-0">
        {proyectosEntregados &&
        proyectosEntregados.map((proyecto, i) => (
          <div className="grid-33 px-0 mov-mb-42" key={i}>
            {
              proyecto.imagen &&
              <GatsbyImage className="mxw-418 mx-auto" image={proyecto.imagen.sourceUrlSharp.childImageSharp.gatsbyImageData} alt="holamundo" />
            }
            <h3 className="size-22 mov-size-20 font-tenor mt-35 mov-mt-18 weight-400 color-black-100">{proyecto.nombre}</h3>
          </div>
        ))
        }
      </div>
      <Link to="/proyectos-en-venta/" className="bg-blue-100 color-yellow-100 size-32 mov-size-16 font-tenor mt-73 inline-block px-104 mov-px-76 pt-29 mov-pt-8 pb-29 mov-pb-8 uppercase">Ver más</Link>
    </section>
  );

}