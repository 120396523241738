import React from 'react';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import InnerImageZoom from 'react-inner-image-zoom';

export default function MapaInicio({imagen, imagenMovil,puntos1,puntos2}) {
  return (
    <section className="grid-100 px-0 bg-yellow-100 ubicacion line-0 mov-px-0" id="ubicacion">
      <div className="grid-50 px-0 mov-px-0">
        {/*<GatsbyImage className="w-100" image={imagen.sourceUrlSharp.childImageSharp.gatsbyImageData} alt="holamundo" />*/}
        {
          imagen &&
          <InnerImageZoom className="only-desk" src={imagen.sourceUrl} zoomSrc={imagen.sourceUrl} />
        }
        {
          imagenMovil &&
          <InnerImageZoom zoomScale={.7} className="only-mov" src={imagenMovil.sourceUrl} zoomSrc={imagen.sourceUrl} />
        }
      </div>
      <div className="grid-50 px-0 pt-51 only-desk">
        <div className="mxw-650 mx-auto">
          <h3 className="font-tenor size-22 mb-38 color-blue-100">01. Santa Isabel 169</h3>
          <div className="grid-100 px-0">
            <div className="grid-50 px-0">
              <div className="listing size-22 font-tenor color-blue-100" dangerouslySetInnerHTML={{__html: puntos1}}/>
            </div>
            <div className="grid-50 px-0">
              <div className="listing size-22 font-tenor color-blue-100" dangerouslySetInnerHTML={{__html: puntos2}}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

}