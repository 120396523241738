import React, {useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

export default function ContactInicio({intro}) {

  const [envio, setEnvio] = useState("")

  return (
    <section className="bg-yellow-100 grid-100 pt-88 px-78 form--table flex wrap pb-81 contacto mov-pt-19" id="contacto">
      <div className="grid-40 mobile-grid-100 px-48 py-18 px-60 mov-px-34 mov-py-14">
        <h2 className="size-70 mobile-grid-100 font-poppins weight-400 mov-size-32 mov-px-0 color-blue-100">CONTACTO</h2>
      </div>
      <div className="grid-60 pt-42 px-91 pb-30 mov-px-34 mov-pt-19 mov-pb-25">
        <div className="size-22 font-tenor text-my-0 mov-size-12-1 mov-br-none color-blue-100" dangerouslySetInnerHTML={{ __html: intro }} />
      </div>
      <div className="grid-100 pt-44 pb-44 flex wrap align-start px-60 mov-pb-44 mov-pt-11">
        <Formik
          initialValues={{ Email: '', Nombre: '', Telefono: '', Apellido: '' , Acepto_condiciones: '', Acepto_envio: ''}}
          validate={values => {
            const errors = {};

            if (!values.Nombre) {
              errors.Nombre = 'Campo requerido';
            }

            if (!values.Apellido) {
              errors.Apellido = 'Campo requerido';
            }

            if (!values.Telefono) {
              errors.Telefono = 'Campo requerido';
            }
            if (!values.Acepto_condiciones) {
              errors.Acepto_condiciones = 'Campo requerido';
            }
            if (!values.Acepto_envio) {
              errors.Acepto_envio = 'Campo requerido';
            }

            if (!values.Email) {
              errors.Email = 'Campo requerido';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)
            ) {
              errors.Email = 'Correo Invalido';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting,resetForm }) => {
            try{
              fetch('https://www.labok.com/santa-isabel-169/wp-json/contact-form-7/v1/contact-forms/432/feedback', {
                method: 'POST',
                body:
                  'Nombre=' + values.Nombre +
                  '&Apellido=' + values.Apellido +
                  '&Telefono=' + values.Telefono +
                  '&Email=' + values.Email,
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              })
                .then(res => res.json())
                .then(json => {
                  if(json.status === "mail_sent"){
                    setEnvio(true)
                    setSubmitting(false);
                    resetForm();
                    console.log("correo enviado");
                  }
                  else{
                    setEnvio(false)
                  }
                })
            }catch (error) {
              console.error(error);
              setEnvio(false)
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form className="grid-100 form-inicio">
              <div className="grid-33 mb-13 mmn-h-78 mov-mb-21">
                <Field className="form-control size-22 w-85p mov-size-16" type="text" name="Nombre" placeholder="Nombre*" />
                <ErrorMessage className="form-error color-orange-100" name="Nombre" component="div" />
              </div>
              <div className="grid-33 mb-13 mmn-h-78 mov-mb-21">
                <Field className="form-control size-22 w-85p mov-size-16" type="text" name="Email" placeholder="Email*" />
                <ErrorMessage className="form-error color-orange-100" name="Email" component="div" />
              </div>
              <div className="grid-33 mb-13 mmn-h-78 only-desk">&nbsp;</div>
              <div className="grid-33 mb-13 mmn-h-78 mov-mb-21">
                <Field className="form-control size-22 w-85p mov-size-16" type="text" name="Apellido" placeholder="Apellido*" />
                <ErrorMessage className="form-error color-orange-100" name="Apellido" component="div" />
              </div>
              <div className="grid-33 mb-13 mmn-h-78 mov-mb-21">
                <Field className="form-control size-22 w-85p mov-size-16" type="tel" name="Telefono" placeholder="Teléfono*" />
                <ErrorMessage className="form-error color-orange-100" name="Telefono" component="div" />
              </div>
              <div className="grid-33 mb-13 mmn-h-78 only-desk">
                <button className="size-22 btn-outline-orenge-100 w-346 pt-10 pb-10 px-130 mov-size-16 mov-w-151 color-orange-100 font-sangbleu mt-n10" type="submit" disabled={isSubmitting}>Enviar</button>
              </div>
              <div className="grid-33">
                <label className="color-orange-100 font-tenor size-15 mov-size-12-1 form-acept" htmlFor="Acepto_condiciones"><Field className="checkbox--orange-100 mr-9 mov-mr-10" type="checkbox" name="Acepto_condiciones" id="Acepto_condiciones" />Acepto las condiciones de la politica de privacidad.</label>
                <ErrorMessage className="form-error color-orange-100 mov-mb-13" name="Acepto_condiciones" component="div" />
              </div>
              <div className="grid-33 mov-mt-10">
                <label className="color-orange-100 font-tenor size-15 mov-size-12-1 form-acept" htmlFor="Acepto_envio"><Field className="checkbox--orange-100 mr-9 mov-mr-10" type="checkbox" name="Acepto_envio" id="Acepto_envio" />Acepto el envio de precios, información comercial y mails.</label>
                <ErrorMessage className="form-error color-orange-100" name="Acepto_envio" component="div" />
              </div>
              <div className="grid-33 mb-13 mmn-h-78 only-mov mov-mt-33 mov-text-center">
                <button className="size-22 btn-outline-orenge-100 w-346 pt-10 pb-10 px-130 mov-size-16 mov-w-151 color-orange-100 font-sangbleu" type="submit" disabled={isSubmitting}>Enviar</button>
              </div>
              { (envio === false && envio !== "") && <div className="grid-33 font-tenor block mov-size-16 mov-text-center mov-mt-13">Error en el envio</div>}
              { (envio === true && envio !== "") && <div className="grid-33 font-tenor mov-mt-13 block mov-text-center mov-size-16">Correo enviado</div>}
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );

}