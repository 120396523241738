import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export default function BannerInicio({ logo, slogan, caracteristicas, foto1, foto2, foto3 }) {
  return (
    <>
    <section className="banner bg-yellow-100 flex only-desk">
      <div className="banner__left">
        <GatsbyImage
          className="banner__logo mb-117"
          image={logo.sourceUrlSharp.childImageSharp.gatsbyImageData}
          alt={logo.alt ? logo.alt : logo.title} />
        <div className="line-small bg-black-100"></div>
        <div className="color-white-100 size-30 font-tenor text-my-24 color-yellow-100" dangerouslySetInnerHTML={{ __html: slogan }} />
        <div className="line-small bg-black-100"></div>
        <div className="color-white-100 size-20 font-poppins spacing-02em uppercase mt-91 color-yellow-100"
             dangerouslySetInnerHTML={{ __html: caracteristicas }} />
      </div>
      <div className="banner__right relative w-full">
        <div className="banner__square absolute bg-blue-100"></div>
        <GatsbyImage
          className="banner__img--foto1 absolute"
          image={foto1.sourceUrlSharp.childImageSharp.gatsbyImageData}
          alt={foto1.alt ? foto1.alt : foto1.title} />
        <GatsbyImage
          className="banner__img--foto2 absolute"
          image={foto2.sourceUrlSharp.childImageSharp.gatsbyImageData}
          alt={foto2.alt ? foto1.alt : foto2.title} />
        <GatsbyImage
          className="banner__img--foto3 absolute"
          image={foto3.sourceUrlSharp.childImageSharp.gatsbyImageData}
          alt={foto3.alt ? foto1.alt : foto3.title} />
      </div>
    </section>
      <section className="only-mov mov-mt-48">
        <div className="grid-100 bg-orange-100 px-0 flex wrap">
          <div className="mobile-grid-50 px-0">
            <GatsbyImage
              className="mov-w-160"
              image={foto3.sourceUrlSharp.childImageSharp.gatsbyImageData}
              alt={foto3.alt ? foto1.alt : foto3.title} />
          </div>
          <div className="mobile-grid-50 flex align-center">
            <div>
              <div className="line-small bg-black-100"></div>
              <div className="color-white-100 mov-size-16 font-tenor color-yellow-100" dangerouslySetInnerHTML={{ __html: slogan }} />
              <div className="line-small bg-black-100"></div>
            </div>
          </div>
          <div className="mobile-grid-100 text-center mov-mt-n15 mov-mb-16">
            <GatsbyImage
              className="mov-w-242 mov-mx-auto"
              image={logo.sourceUrlSharp.childImageSharp.gatsbyImageData}
              alt={logo.alt ? logo.alt : logo.title} />
          </div>

        </div>
        <div className="grid-100 bg-blue-100 relative mov-h-224">
          <GatsbyImage
            className="banner__img--foto1 absolute"
            image={foto2.sourceUrlSharp.childImageSharp.gatsbyImageData}
            alt={foto1.alt ? foto2.alt : foto1.title} />
          <GatsbyImage
            className="banner__img--foto2 absolute"
            image={foto1.sourceUrlSharp.childImageSharp.gatsbyImageData}
            alt={foto2.alt ? foto2.alt : foto2.title} />
        </div>
      </section>
    </>
  )

}